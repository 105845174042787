import "./Style.css"

export const Footer = () => {
  return (
    <div>
      <p className="footer">
        Mina Delegation Epoch is made from Carbonara by{" "}
        <a href="https://mina.westake.club">WeStake.Club⚡️</a>. Join us on <a href="https://discord.gg/4GnkRqwsDK">Discord</a> for staking help.
        <br />
        <a href="https://mina.tools/">Discover more mina.tools</a>
      </p>
    </div>
  );
};
