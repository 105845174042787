import { MAX_SLOT, MIN_SLOT } from "../../const";
import "./Style.css";

interface IProps {
  percentage?: number;
  currentSlot?: number;
  remainingTime: {
    days: number;
    hours: number;
    minutes: number;
  };
  error?: boolean;
}

export const ProgressBar = ({
  percentage = 0,
  currentSlot = 0,
  remainingTime,
  error,
}: IProps) => {
  const epochMsg = !error
    ? `
  Next epoch in ${remainingTime.days} days ${remainingTime.hours}h and ${remainingTime.minutes}m`
    : "There was an error fetching data";
  return (
    <div>
      <div className="progressbar">
        <div id="center">
          <div
            id="main"
            style={{
              width: `${percentage}%`,
            }}
          >
            <p className="current-block-text">Slot : {currentSlot}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h5>Slot : {MIN_SLOT}</h5>
        </div>
        <div className="col-12 col-md-6 hide-mobile">
          <h5 className={`align-center ${error ? "error-message" : ""}`}>
            {epochMsg}
          </h5>
        </div>
        <div className="col align-right">
          <h5>Slot : {MAX_SLOT}</h5>
        </div>
      </div>
      <br />
      <div className="show-mobile">
        <h5 className={`${error && "error-message"}`}>{epochMsg}</h5>
      </div>
      <div className="description">
        <h6>
          {" "}
          *The staking ledger is "sealed" at block 290 of each epoch. Meaning
          that if your delegation is snarked before block 290, then your
          delegation will be effective at the next epoch, otherwise after the
          290th block of the epoch it will be available in 2 epochs. Epoch
          Calculator offers an estimation, where for simplicity we assume a
          regular transaction traffic and calculate slot 6000th to be the best slot
          to delegate, after that the estimation starts to be less likely. 1
          epoch is around 14 days.
        </h6>
      </div>
    </div>
  );
};
