import axios from "axios"

export const getSlotEpoch = async () => {
  try {
    const data = await axios.get(`${process.env.REACT_APP_SERVER}`)
    return data.data
  } catch (error) {
    return {
      epoch: "0",
      slot: "0"
    }
  }
}
