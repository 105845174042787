import { PROBABILITY_MAX_SLOT, PROBABILITY_MIN_SLOT } from "../../const";
import "./Style.css";

interface IProps {
  currentEpoch?: number;
  epochEffective?: number;
  remainingTime: {
    days: number;
    hours: number;
    minutes: number;
  };
  error?: boolean;
  slot: number;
}

export const HeaderText = ({
  epochEffective = 0,
  currentEpoch = 0,
  remainingTime,
  error,
  slot,
}: IProps) => {
  const epochMsg = !error
    ? `
  ${remainingTime.days} days ${remainingTime.hours} hours and ${remainingTime.minutes} minutes`
    : " :(";

  return !error ? (
    <div className="header-text">
      <h1>✅ Mina Epoch Calculator</h1>
      <h2>
        On the Mina Protocol Blockchain we are currently on{" "}
        <strong>Epoch {currentEpoch}</strong>.
      </h2>
      <h2>
        If you delegate now, your delegation{" "}
        {slot > PROBABILITY_MIN_SLOT && slot < PROBABILITY_MAX_SLOT
          ? "probably"
          : ""}{" "}
        will be effective on <strong>Epoch {epochEffective} </strong>.
      </h2>
      <h2>
        The best time to delegate in order to have the least delegation delay
        should be between now and {epochMsg}.*
      </h2>
      <h4 className="aqua">
        Are you looking for a realiable staking pool?{" "}
        <strong>
          <a className="bisque" href="https://mina.westake.club">
            WeStake.Club⚡️
          </a>
        </strong>{" "}
        has a tiny 2% fee and supports the development of Mina ecosystem tools.
      </h4>
    </div>
  ) : (
    <div className="header-text">
      <h1>Mina Epoch Calculator</h1>
    </div>
  );
};
