import "./Style.css";
import { ProgressBar } from "../progressBar/ProgressBar";
import { HeaderText } from "../headerText/HeaderText";
import { Footer } from "../footer/Footer";
import { getSlotEpoch } from "./api";
import { useEffect, useState } from "react";
import { BEST_SLOT, FETCH_DATA_INTERVAL, MAX_SLOT, SLOT_DURATION } from "../../const";

export const Container = () => {
  const [epochData, setEpochData] = useState({
    slot: 0,
    epoch: 0,
  });
  const [epochEffective, setEpochEffective] = useState(0);
  const [epochPercentage, setEpochPercentage] = useState(0);
  const [error, setError] = useState(false);
  const [nextEpochTime, setNextEpochTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });
  const [bestEpochTime, setBestEpochTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  useEffect(() => {
    fetchData();
    setInterval(() => {
      fetchData();
    }, FETCH_DATA_INTERVAL);
  }, []);

  const fetchData = async () => {
    const { slot, epoch } = await getSlotEpoch();
    if (slot === "0" && epoch === "0") {
      setError(true);
    }
    const realSlot = +slot % (MAX_SLOT + 1);
    setEpochData({
      slot: +slot % (MAX_SLOT + 1),
      epoch: +epoch,
    });
    const slotsPerEpoch = MAX_SLOT;
    const slotDuration = SLOT_DURATION;
    const lastTime = ((slotsPerEpoch - +realSlot) * slotDuration) / 1000;
    let bestTime =
      ((slotsPerEpoch + BEST_SLOT - +realSlot) * slotDuration) / 1000;
    if (+slot < BEST_SLOT) {
      bestTime = ((BEST_SLOT - +realSlot) * slotDuration) / 1000;
    }
    const nextEpochEffective = +realSlot < BEST_SLOT ? +epoch + 2 : +epoch + 3;
    const percentage = parseInt(((100 * +realSlot) / slotsPerEpoch).toFixed(0));
    setEpochPercentage(percentage);
    setEpochEffective(nextEpochEffective);
    setNextEpochTime({
      ...calculateTime(lastTime),
    });
    setBestEpochTime({
      ...calculateTime(bestTime),
    });
  };

  const calculateTime = (time: number) => {
    const days = Math.floor(time / 60 / 60 / 24);
    const leave1 = time % (24 * 3600);
    const hours = Math.floor(leave1 / 3600);
    const leave2 = leave1 % 3600;
    const minutes = Math.floor(leave2 / 60);
    return {
      days,
      hours,
      minutes,
    };
  };

  return (
    <div>
      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div className="context">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-lg-10">
              <HeaderText
                epochEffective={epochEffective}
                currentEpoch={+epochData.epoch}
                remainingTime={bestEpochTime}
                error={error}
                slot={+epochData.slot}
              />
              <ProgressBar
                percentage={epochPercentage}
                currentSlot={+epochData.slot}
                remainingTime={nextEpochTime}
                error={error}
              />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
